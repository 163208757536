// src/sass/variables.sass
// Globals
$heading-font-family: "Roboto", sans-serif !default

.v-application
  color: #FFFFFF

.remove-scroll
  margin: 0
  padding: 0
  overflow: hidden

.h-100
  height: 100%

.w-100
  width: 100%

.mx-200
  max-width: 200px

.section-header
  background-color: var(--v-primary-base)
  color: #FFFFFF
  text-align: center
  margin: auto
  font-size: 1.5em

.sub-section-header
  background-color: #009688
  color: #FFFFFF
  text-align: center
  margin: auto
  font-size: 1em

.border-1
  border: 1px solid

.border-white
  border-color: #FFF

.warning
  td
    background-color: var(--v-warning-base)

.accent
  td
    background-color: var(--v-accent-base)

.pointer
  cursor: pointer

.not-allowed
  cursor: not-allowed

.v-skeleton-loader__image
  height: 100% !important

.v-dialog--fullscreen
  overflow-x: hidden
